import React from 'react'
import './About.css'
import about from './about.png'
import Heading from '../Heading/Heading'
import my from '../Home/Optimized-my-removebg-preview.png'


const About = () => {
    return (
        <div className='container' id='about'>
            <Heading heading='About' />
            <div className="about">
                <div className="about-left">
                    <div className="about-img-container">
                        <img src={about} alt="" />
                        {/* <img src={my} alt="" /> */}
                    </div>
                </div>
                <div className="about-right">
                    {/* <p>
                        As a skilled software developer, I possess a strong background in Information Technology, specializing in JavaScript, NodeJS, ExpressJS, ReactJS, Redux, and MongoDB.
                        <br />
                        My portfolio showcases a range of projects, including a dynamic URL shortening platform with integrated payment solutions and comprehensive analytics. Additionally, I have developed a thrilling multiplayer game that allows users to engage in real-time gaming experiences.
                        <br />
                        I am dedicated to staying updated with the latest industry trends and continuously improving my skills. Holding certifications in problem-solving and data structures, I ensure that I am well-equipped to tackle complex challenges and deliver efficient solutions.
                        <br />
                        With a keen eye for detail and a collaborative mindset, I consistently deliver high-quality, scalable solutions. I am excited to bring my technical expertise, innovative problem-solving skills, and strong attention to detail to contribute to your team's success.
                        <br />
                        Let's connect to discuss how I can make a valuable impact as a software developer in your organization.
                    </p> */}
                    <p>
                    I am a passionate and driven software developer with a deep love for technology and a knack for crafting exceptional web applications that make a lasting impact. With a strong command of JavaScript, NodeJS, ExpressJS, ReactJS, Redux, and MongoDB, I possess the technical skills necessary to turn your ideas into reality.
                        <br />
                        <br />
                        Throughout my internships, I have successfully tackled diverse projects, ranging from developing innovative subscription models to seamlessly integrating payment gateways and implementing robust authentication systems. These experiences have sharpened my problem-solving abilities and fueled my desire to create solutions that drive business growth and deliver exceptional user experiences.
                        <br />
                        <br />
                        Don't just take my word for it; explore my portfolio, where you'll discover exciting projects such as a dynamic URL shortening platform and an exhilarating multiplayer game that connects players worldwide in real-time battles.
                        <br />
                        <br />
                        I pride myself not only on my technical prowess, but also on my collaborative nature, dedication to continuous learning, and drive to stay ahead of industry trends. Let's connect on LinkedIn and explore how I can leverage my skills to contribute to the success of your team. Together, we can build something truly extraordinary.
                    </p>

                    <div className="about-btn-container">
                        <a href="https://drive.google.com/file/d/18tgJ2RQVGY1I_fK8HkxpNErP6icaPZFa/view?usp=sharing" className='btn' target="_blank">Resume</a>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default About