import React from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import About from './components/About/About';
import Skills from './components/Skills/Skills';
import Experience from './components/Experience/Experience.jsx';
import Projects from './components/Projects/Projects';
import OpenSource from './components/OpenSource/OpenSource';
import Contact from './components/Contact/Contact';

function App() {
  return (
    <div id='main' className=''>
      <Navbar />
      <Home />
      <About />
      <Skills />
      <Experience />
      <Projects />
      {/* <OpenSource /> */}
      <Contact />
    </div>
  );
}

export default App;
