import React from 'react'
import Heading from '../Heading/Heading'
import html from '../images/html.png'
import mongodb from '../images/mongodb.png'
import cpp from '../images/cpp.png'
import css from '../images/css.png'
import js from '../images/js.png'
import react from '../images/react.png'
import redux from '../images/redux.png'
import node from '../images/nodejs.png'
import express from '../images/express.png'
import api from '../images/api.png'
import postman from '../images/postman.png'
import vscode from '../images/vscode.png'
import docker from '../images/docker.png'
import aws_s3 from '../images/aws_s3.png'
import git from '../images/git.png'
import './Skills.css'


const Skills = () => {
    return (
        <div className='container' id='skills'>
            <Heading heading='Skills'/>
            <div className="skills">
                <div className="skill-img-container" data-bs-toggle="tooltip" data-bs-placement="top" title="HTML">
                    <img src={html} alt="HTML" data-bs-toggle="tooltip" data-bs-placement="top" title="HTML" />
                    <p>HTML</p>
                </div>
                <div className="skill-img-container " data-toggle="tooltip" title='CSS'>
                    <img src={css} alt="CSS" aria-label='CSS' />
                    <p>CSS</p>
                </div>
                <div className="skill-img-container " data-toggle="tooltip" title='JavaScript'>
                    <img src={js} alt="JavaScript" aria-label='JavaScript' />
                    <p>JavaScript</p>
                </div>
                <div className="skill-img-container " data-toggle="tooltip" title='NodeJS'>
                    <img src={node} alt="Node" aria-label='Node' />
                    <p>NodeJs</p>
                </div>
                <div className="skill-img-container " data-toggle="tooltip" title='Express'>
                    <img src={express} alt="Express" aria-label='Express' />
                    <p>ExpressJS</p>
                </div>
                <div className="skill-img-container " data-toggle="tooltip" title='ReactJs'>
                    <img src={react} alt="React" aria-label='React' />
                    <p>ReactJS</p>
                </div>
                <div className="skill-img-container " data-toggle="tooltip" title='Redux'>
                    <img src={redux} alt="Redux" aria-label='Redux' />
                    <p>Redux</p>
                </div>
                <div className="skill-img-container " data-toggle="tooltip" title='MongoDB'>
                    <img src={mongodb} alt="MongoDB" aria-label='MongoDB' />
                    <p>MongoDB</p>
                </div>
                <div className="skill-img-container " data-toggle="tooltip" title='C++'>
                    <img src={cpp} alt="C++" aria-label='C++' />
                    <p>C++</p>
                </div>

                <div className="skill-img-container " data-toggle="tooltip" title='API'>
                    <img src={api} alt="API" aria-label='API' />
                    <p>API</p>
                </div>

                {/* <div className="skill-img-container " data-toggle="tooltip" title='VsCode'>
                    <img src={vscode} alt="VsCode" aria-label='VsCode' />
                </div> */}
                <div className="skill-img-container " data-toggle="tooltip" title='Git'>
                    <img src={git} alt="Git" aria-label='Git' />
                    <p>Git</p>
                </div>
                <div className="skill-img-container " data-toggle="tooltip" title='Docker'>
                    <img src={docker} alt="Docker" aria-label='Docker' />
                    <p>Docker</p>
                </div>
                <div className="skill-img-container " data-toggle="tooltip" title='AWS S3'>
                    <img src={aws_s3} alt="AWS S3" aria-label='AWS_S3' />
                    <p>AWS S3</p>
                </div>
 
                <div className="skill-img-container " data-toggle="tooltip" title='PostMan'>
                    <img src={postman} alt="PostMan" aria-label='PostMan' />
                    <p>Postman</p>
                </div>
 
            </div>
        </div>
    )
}

export default Skills