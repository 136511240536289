import React from 'react'
import './Projects.css'

const ProjectCard = ({ project }) => {
    return (
        <div className='projectCard' id='projectCard'>
            <div class="card" style={{ width: "18rem" }}>
                <img src={project.image} class="card-img-top" alt="..." />
                <div class="card-body">
                    <h5 class="card-title">{project.name}</h5>
                    <p>{project.description}</p>
                    <div className="project-links">
                        <a href={project.Live} class="">Live</a>
                        <a href={project.github} class="">GitHub</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectCard