import React,{useEffect, useState} from 'react'
import './Navbar.css'

const Navbar = () => {

  const [mode, setMode] = useState('dark')

  const handleMode = () => {
    if(mode === 'light'){
      setMode('dark')
      window.document.getElementById('main').classList.add('bg-dark');
      window.document.body.style.backgroundColor = '#121212';
      window.document.getElementById('home').classList.add('home-dark');
      window.document.getElementById('about').classList.add('about-dark');
      window.document.getElementById('projects').classList.add('projects-dark');
      window.document.getElementById('skills').classList.add('skills-dark');
      const cardsElements = window.document.getElementsByClassName('experienceCard');
      for (let i = 0; i < cardsElements.length; i++) {
        const element = cardsElements[i];
        element.classList.add('experienceCard-dark');
      }
      // window.document.getElementById('experienceCard').classList.add('experienceCard-dark');
      window.document.getElementById('experience').classList.add('experience-dark');
      window.document.getElementById('projects').classList.add('projects-dark');
      window.document.getElementById('contact').classList.add('contact-dark');
      // window.document.getElementById('openSource').classList.add('openSource-dark');
    }else{
      setMode('light')
      window.document.body.style.backgroundColor = '#f5f5f5'
      window.document.getElementById('main').classList.remove('bg-dark');
      window.document.getElementById('home').classList.remove('home-dark');
      window.document.getElementById('about').classList.remove('about-dark');
      window.document.getElementById('projects').classList.remove('projects-dark');
      window.document.getElementById('skills').classList.remove('skills-dark');
      window.document.getElementById('experienceCard').classList.remove('experienceCard-dark');
      window.document.getElementById('experience').classList.remove('experience-dark');
      window.document.getElementById('projects').classList.remove('projects-dark');
      window.document.getElementById('contact').classList.remove('contact-dark');
      // window.document.getElementById('openSource').classList.remove('openSource-dark');

      const cardsElements = window.document.getElementsByClassName('experienceCard');
      for (let i = 0; i < cardsElements.length; i++) {
        const element = cardsElements[i];
        element.classList.remove('experienceCard-dark');
      }
    }
  }

  useEffect(()=>{
    handleMode();
  },[])

  return (
    // <div>
      <nav class={`navbar fixed-top navbar-expand-lg navbar-${mode} bg-${mode}`}>
        <div class="container-fluid">
          <a class="navbar-brand" href="#">PortFolio</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#home">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#about">About</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#skills">Skills</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#experience">Experience</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#projects">Projects</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#contact">Contact</a>
              </li>
              {/* <a href='https://www.linkedin.com/in/harshilsharmaa' className='collab'>Let's Collab</a> */}
             
            </ul>
            <div class="form-check form-switch">
              <input onClick={handleMode} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
              <label style={mode=='dark'?{color:"white"}:{color:"black"}} class="form-check-label" for="flexSwitchCheckDefault">Dark Mode</label>
            </div>
          </div>
        </div>
      </nav>
    // </div>
  )
}

export default Navbar