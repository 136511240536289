import React from 'react'
import './Heading.css'

const Heading = ({heading}) => {
  return (
    <div className='heading' id='heading'>
        <h1 className='underline-animation'>{heading}</h1>
    </div>
  )
}

export default Heading