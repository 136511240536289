import React from 'react'
import Heading from '../Heading/Heading'
import './Experience.css'
import ExperienceCard from './ExperienceCard'
import experience from './experience.json'

const Experience = () => {
  return (
    <div className='container' id='experience'>
        <Heading heading='Experience'/>
        <div className="experience">
          {
            experience.map((exp, index) => {
              return <ExperienceCard key={index} exp={exp}/>
            })
          }
        </div> 
    </div>
  )
}

export default Experience