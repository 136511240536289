import React from 'react'
import './ExperienceCard.css'
import nodejs from '../images/nodejs.png'
import mongodb from '../images/mongodb.png'
import react2 from '../images/react2.png'
import redux from '../images/redux.png'
import express from '../images/express.png'
import socketio from '../images/socketio.png'
import chartjs from '../images/chartjs.svg'
import jspdf from '../images/jspdf.svg'
import web from '../images/web.png'
import web2 from '../images/web2.gif'

const ExperienceCard = ({ exp }) => {

    const {
        title,
        duration,
        description,
        certificate,
        lor,
        technologies,
    } = exp

    const technologyImages = {
        nodejs: nodejs,
        express: express,
        react2: react2,
        redux: redux,
        mongodb: mongodb,
        chartjs: chartjs,
        jspdf: jspdf,
        socketio: socketio
    };

    return (
        <div className='experienceCard' id='experienceCard'>
            <div className="experienceCard-heading">
                <h2>{title} </h2>
                <h5>{duration}</h5>
            </div>
            <div className="experienceCard-body">

                <div className="experienceCard-body-left">
                    <img className='experience-gif' src={web2} alt="" />
                    <div className="certificates">
                        <a href={certificate}>Certificate</a>
                        <a href={lor}>LOR</a>
                    </div>
                </div>

                <div className="experienceCard-body-right">

                    <ul>
                        {
                            description.map((desc, index) => {
                                return (
                                    <li key={index}>{desc}</li>
                                )
                            })
                        }
                    </ul>
                    <div className="technologies">

                        {
                            technologies.map((tech, index) => {
                                return (
                                    <div className=" " data-toggle="tooltip" title={tech} key={index}>
                                        <img src={technologyImages[tech]} alt={tech} aria-label={tech} />

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ExperienceCard