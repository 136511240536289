import React from 'react'
import Heading from '../Heading/Heading'
import './Projects.css'
import ProjectCard from './ProjectCard'
import data from './data.json'

const Projects = () => {
  return (
    <div className='container' id='projects'>
        <Heading heading="Projects"/>
        <div className="projects">
            {
                data.projects.map((project, index) => {
                    return (
                        <ProjectCard key={index} project={project} />
                    )
                })
            }
        </div>    
    </div>
  )
}

export default Projects